<template>
  <v-sheet tile>
    <section id="launch-hero">
      <div class="mx-auto" height="100%" style="margin-top: -65px">
        <div class="player-container">
          <vue-plyr
            ref="plyr"
            :options="options"
            muted
            autoplay
            style="width: 100%; margin-left: -1px"
          >
            <!-- :style="'width:' + windowWidth + 'px'" -->
            <video
              playsinline
              @ready="onReady"
              @play="updateElement"
              @ended="videoEnded"
              allowfullscreen="false"
              :data-poster="
                windowWidth <= 768
                  ? 'img/about/PressLaunch_Mobile.png'
                  : 'img/about/PressLaunch.png'
              "
              :src="
                windowWidth <= 768
                  ? 'img/about/PressLaunch_Mobile.mp4'
                  : 'img/about/PressLaunch.mp4'
              "
            ></video>
          </vue-plyr>
          <!-- <v-app-bar color="#262626" fixed app elevation="1">
            <v-img
              style="
                position: absolute;
                z-index: 99;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              "
              @click="routerGo('/')"
              alt="Charged Factory | Electronic Vehicle Indonesia"
              src="../../../public/img/logo/Charged.svg"
              min-width="125px"
              min-height="32px"
              max-width="125.05px"
              max-height="32px"
            ></v-img>
            <v-menu
              offset-y
              style="position: absolute; width: 100%; z-index: 99"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                  color="white"
                  style="
                    text-transform: none !important;
                    z-index: 99;
                    position: absolute;

                    right: 16px;
                  "
                >
                  {{ getLanguage === "id" ? "ID" : "EN"
                  }}<v-icon color="white">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="changeLanguage('en')">
                  <v-list-item-title>EN (English)</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeLanguage('id')">
                  <v-list-item-title>ID (Indonesia)</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-app-bar> -->
        </div>
      </div>
    </section>
    <section id="launch-description">
      <!-- class="mx-auto my-3 py-lg-12 px-lg-8 px-md-1 py-md-6 px-sm-1 py-sm-4" -->
      <v-card
        :class="
          'px-5 my-16 py-7 my-md-16 py-md-16 ' +
          (windowWidth <= 768 ? 'mx-0' : 'mx-md-auto')
        "
        :width="windowWidth <= 768 ? '100%' : '50%'"
        tile
        outlined
      >
        <v-card-title
          style="word-break: break-word"
          :class="
            'mb-8 px-0 ' +
            (windowWidth <= 768 ? 'charged-title-mobile' : 'charged-title')
          "
          v-text="lang[getLanguage].PL_DESCRIPTION_TITLE"
        >
        </v-card-title>
        <v-card-text class="px-0">
          <p
            :class="windowWidth <= 768 ? 'charged-body-h2' : 'charged-body-h1'"
            v-html="lang[getLanguage].PL_DESCRIPTION_TEXT"
          ></p>
        </v-card-text>
      </v-card>
    </section>
    <section
      id="launch-factory"
      class="d-flex align-end justify-center mx-0 px-0"
    >
      <v-img
        width="100%"
        min-height="450px"
        class="px-0 mx-0"
        gradient=""
        :src="
          windowWidth <= 768
            ? require('../../../public/img/launch/FactoryMobile.png')
            : require('../../../public/img/launch/Factory.png')
        "
      ></v-img>
    </section>
    <section
      id="launch-believe"
      style="background: #262626"
      class="pt-md-15 pt-0 pb-16"
    >
      <v-row
        class="mx-auto mx-md-16 px-5 px-md-0 pt-15 pb-16 text-over-image-white"
      >
        <v-col cols="12" md="6" class="px-md-3 px-0">
          <v-card-title
            :class="
              'px-md-4 px-0 ' +
              (windowWidth <= 768
                ? 'charged-title-mobile'
                : 'charged-factory-title ')
            "
            style="word-break: break-word"
            v-text="lang[getLanguage].PL_BELIEVE_TITLE"
          >
          </v-card-title>
        </v-col>
        <v-col cols="12" md="6" class="pb-7 px-md-3 px-0">
          <v-card-text
            class="charged-factory-text px-md-4 px-0"
            v-html="lang[getLanguage].PL_BELIEVE_CONTENT"
          >
          </v-card-text>
        </v-col>
      </v-row>
    </section>
    <section
      id="launch-rimba"
      class="d-flex align-end justify-center mx-0 px-0"
    >
      <v-img
        width="100%"
        min-height="450px"
        class="px-0 mx-0"
        gradient=""
        :src="
          windowWidth <= 768
            ? require('../../../public/img/launch/RimbaBikeMobile.png')
            : require('../../../public/img/launch/RimbaBike.png')
        "
      ></v-img>
    </section>
    <section id="launch-rimba-project">
      <v-row class="mx-auto mx-md-16 px-5 px-md-0 mt-13 mb-16 pb-7">
        <v-col cols="12">
          <v-card-title
            :class="
              'px-md-4 px-0 pb-md-2 ' +
              (windowWidth <= 768
                ? 'charged-title-mobile'
                : 'charged-rimba-title')
            "
            v-text="lang[getLanguage].PL_RIMBA_TITLE"
          >
          </v-card-title>
        </v-col>
        <v-col cols="12" md="7" class="px-md-3 px-0">
          <v-card-text
            style="padding-top: 6px"
            class="charged-rimba-text px-md-4 px-0 pb-0"
            v-text="lang[getLanguage].PL_RIMBA_CONTENT"
          >
          </v-card-text>
        </v-col>
        <v-col
          cols="12"
          md="5"
          :class="
            'd-flex px-md-3 px-0 ' +
            (windowWidth <= 560 ? 'justify-start' : 'justify-end')
          "
        >
          <v-btn
            text
            class="charged-rimba-button pa-0 no-background-hover"
            :ripple="false"
            @click="routerGo('/rimba')"
          >
            <span
              class="pr-2 charged-rimba-button text-primary"
              v-text="lang[getLanguage].PL_RIMBA_BUTTON"
            ></span>
            <v-img
              src="../../../public/img/launch/RimbaLogo.png"
              :max-width="windowWidth <= 560 ? '120px' : '150px'"
            ></v-img>
            <v-icon right class="text-primary">mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </section>
    <!-- <section id="about-environment">
      //class="my-4 flex-column flex-md-row v-card mx-0" 

      <v-row
        justify="space-around"
        align="center"
        align-content="center"
        class="
        v-card mx-0 mt-md-5 mt-0 flex-column flex-md-row mb-16 pb-4 pb-md-16
      "
      >
        <v-col md="5" cols="12" class="pa-0 ml-md-2 mt-md-16 mb-16">
          <v-img
            :src="imgSrc"
            :lazy-src="metaImg"
            :height="windowWidth <= 768 ? '680px' : '690px'"
            position="left center"
            fullscreen
          ></v-img>
        </v-col>
        <v-col md="5" cols="12" class="px-5 ">
          <v-card-title
            style="word-break: break-word"
            :class="
              'px-0 mt-md-16 pt-md-7 ' +
                (windowWidth <= 768
                  ? 'charged-title-mobile'
                  : 'charged-title-2')
            "
            v-text="lang[getLanguage].PL_COMMITMENT_TITLE"
          >
          </v-card-title>
          <v-card-text
            class="charged-body-h2 px-0 mt-5 mt-md-9 mb-md-16 pb-md-7"
            align-self-end
          >
            <p v-html="lang[getLanguage].PL_COMMITMENT_TEXT"></p>
          </v-card-text>
        </v-col>
      </v-row>
    </section> -->
    <section id="launch-contact" style="background: #6b4693" class="px-md-7">
      <v-row
        justify="space-around"
        class="mt-6 pt-6 pt-md-16 flex-column flex-md-row mx-0 px-5 mx-md-16 px-md-0"
        align="center"
      >
        <v-col cols="12" md="10" class="px-0 pb-10 mt-md-9">
          <v-card-title
            :class="
              'px-0 text-over-image-white py-0 ' +
              (windowWidth <= 768 ? 'charged-title-mobile' : 'charged-title')
            "
            style="word-break: break-word"
            v-html="lang[getLanguage].PL_CONTACT_TITLE"
          ></v-card-title>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="px-0 py-auto d-flex"
          style="align-self: center"
        >
          <v-btn
            :width="windowWidth <= 768 ? '100%' : 'fit-content'"
            color="white"
            :class="
              'charged-button-text no-uppercase py-md-3 px-md-8 ' +
              (windowWidth <= 768 ? 'charged-button' : 'charged-button-mobile')
            "
            x-large
            @click="startChat()"
            style="color: #6b4693"
            elevation="2"
          >
            {{ lang[getLanguage].PL_CONTACT_BUTTON }}
            <v-icon right color="primary"> mdi-whatsapp </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" class="px-0 pt-md-0 mb-16 pb-9">
          <p
            v-html="
              windowWidth < 768
                ? lang[getLanguage].PL_CONTACT_TEXT_MOBILE
                : lang[getLanguage].PL_CONTACT_TEXT
            "
            class="charged-body-h2 text-over-image-white"
          ></p>
        </v-col>
      </v-row>
    </section>
    <v-footer class="pa-0">
      <Footer
        id="footer"
        :isMobileDevice="isMobileDevice"
        :dark="dark"
        :drawer="drawer"
        :windowWidth="windowWidth"
      >
      </Footer>
    </v-footer>
  </v-sheet>
</template>

<script>
import store from "@/store/index";
import Footer from "./FooterPressLaunch";
// import Footer from "./Footer";

export default {
  name: "pressLaunch",
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "description", content: this.metaDesc },
        { property: "og:title", content: this.metaTitle },
        { property: "og:image", content: this.metaImg },
        { property: "og:url", content: this.metaUrl },
        { property: "og:site_name", content: "Charged Asia" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  props: {
    dark: Boolean,
    windowWidth: Number,
    drawer: Boolean,
    isMobileDevice: Boolean,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
  },
  data() {
    return {
      lang: this.$store.state.lang,
      //meta
      metaTitle: "Charged Asia - Ride for Good!",
      metaDesc:
        "Our mission at Charged is 10 million electric vehicles in 10 years. We plan to start with electric motorcycles and lead the mass adoption of sustainable mobility in our region.",
      metaImg:
        "https://firebasestorage.googleapis.com/v0/b/charged-app.appspot.com/o/img%2FCharged%20Factory%20Thumbnail.png?alt=media&token=cc8bf9be-e653-49f2-b520-98f0ce484930",
      metaUrl: "https://charged.asia",
      videoElement: null,
      options: {
        quality: { default: "1080p" },
        controls: ["play-large"],
        hideControls: false,
      },
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/charged-app.appspot.com/o/img%2FCharged%20Factory%20High%20Res.png?alt=media&token=4b2e7a77-e3db-4169-b5b5-10dff28ff515",
    };
  },
  watch: {},
  components: {
    Footer,
  },
  created() {
    this.changeShowNavigation();
  },
  methods: {
    onReady() {
      if (this.videoElement === null) {
        this.videoElement = this.$refs.plyr.player;
        this.videoElement.muted = true;
        this.videoElement.elements.buttons.play[0].classList = [];
        this.videoElement.elements.buttons.play[0].classList.add(
          "button-hide-me"
        );
        this.videoElement.elements.container.classList.add(
          "plyr--init-hide-controls"
        );
        // this.videoElement.elements.container.classList.add('plyr__control plyr__control--overlaid')
        this.videoElement.play();
      }
    },
    updateElement() {
      if (this.videoElement === null) {
        this.videoElement = this.$refs.plyr.player;
        this.videoElement.play();
      }
    },
    videoEnded() {
      this.videoElement.play();
    },
    // changeShowNavigation() {
    //   store.commit("HideNav");
    // },
    changeLanguage(lang) {
      store.commit("SetLanguage", {
        language: (this.getLanguage = lang),
      });
    },
    startChat() {
      var link = "https://api.whatsapp.com/send?phone=6281237686970&text=";
      if (this.getLanguage === "en") {
        link += "Hello%20Charged";
      }
      if (this.getLanguage === "id") {
        link += "Halo%20Charged";
      }
      window.open(link, "_blank");
    },
    routerGo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

/* .v-responsive__sizer {
  transition: padding-bottom 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
  flex: 1 0 0px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
} */

.text-over-image-white {
  color: #ffffff !important;
}

.text-primary {
  color: #6b4693 !important;
}

.margin-x-340 {
  margin-left: 340px;
  margin-right: 340px;
}

.charged-title {
  font-family: "Montserrat" !important;
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 45px !important;
  letter-spacing: -0.03em !important;
  text-align: left !important;
}

.charged-title-2 {
  font-family: "Montserrat" !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 35px !important;
  letter-spacing: -0.03em !important;
  text-align: left !important;
}

.charged-title-mobile {
  font-family: "Montserrat" !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 36px !important;
  letter-spacing: -0.025em !important;
  text-align: left !important;
}

.charged-body-h1 {
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #353638;
}

.charged-body-h2 {
  font-family: "Work Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #353638;
}

.charged-button-text {
  font-family: "Work Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-align: center;
}

.charged-button {
  border-radius: 8px;
  padding: 12px 24px 12px 32px;
}

.charged-button-mobile {
  border-radius: 8px;
  padding: 10px 24px 10px 24px;
}

.no-uppercase {
  text-transform: unset !important;
}

.theme--light.v-sheet--outlined {
  border: none;
}

/* .v-tab,
.v-tab:hover {
  background: none !important;
} */

.theme--dark.v-btn:hover::before {
  opacity: 1;
}
.plyr--init-hide-controls .plyr__controls {
  display: none;
}
.button-hide-me {
  display: none;
}
.no-background-hover::before {
  background-color: transparent !important;
}
.v-card--link:focus::before {
  opacity: 0;
}

.charged-factory-title {
  /* Title H2/Bold */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  /* or 125% */
  align-items: center;
  letter-spacing: -0.025em;

  /* Charged/Grey/900 */

  color: #ffffff;
}

.charged-factory-text {
  /* Body H2/Regular */

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */

  align-items: center;
  letter-spacing: -0.02em;

  /* Charged/Grey/600 */

  color: #e8e8e8;
}
.charged-rimba-title {
  /* Title H2/Bold */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  /* identical to box height, or 125% */
  align-items: center;
  letter-spacing: -0.025em;

  /* Charged/Grey/100 */

  color: #262626;
}
.charged-rimba-text {
  /* Body H2/Regular */

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */
  align-items: center;
  letter-spacing: -0.02em;

  /* Charged/Grey/200 */

  color: #353638;
}

.charged-rimba-button {
  /* Body H2/Medium */

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */
  text-align: center;
  letter-spacing: -0.02em;

  /* Charged/Purple/300 */

  color: #6b4693;
}
</style>
