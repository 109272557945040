var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{attrs:{"tile":""}},[_c('section',{attrs:{"id":"launch-hero"}},[_c('div',{staticClass:"mx-auto",staticStyle:{"margin-top":"-65px"},attrs:{"height":"100%"}},[_c('div',{staticClass:"player-container"},[_c('vue-plyr',{ref:"plyr",staticStyle:{"width":"100%","margin-left":"-1px"},attrs:{"options":_vm.options,"muted":"","autoplay":""}},[_c('video',{attrs:{"playsinline":"","allowfullscreen":"false","data-poster":_vm.windowWidth <= 768
                ? 'img/about/PressLaunch_Mobile.png'
                : 'img/about/PressLaunch.png',"src":_vm.windowWidth <= 768
                ? 'img/about/PressLaunch_Mobile.mp4'
                : 'img/about/PressLaunch.mp4'},on:{"ready":_vm.onReady,"play":_vm.updateElement,"ended":_vm.videoEnded}})])],1)])]),_c('section',{attrs:{"id":"launch-description"}},[_c('v-card',{class:'px-5 my-16 py-7 my-md-16 py-md-16 ' +
        (_vm.windowWidth <= 768 ? 'mx-0' : 'mx-md-auto'),attrs:{"width":_vm.windowWidth <= 768 ? '100%' : '50%',"tile":"","outlined":""}},[_c('v-card-title',{class:'mb-8 px-0 ' +
          (_vm.windowWidth <= 768 ? 'charged-title-mobile' : 'charged-title'),staticStyle:{"word-break":"break-word"},domProps:{"textContent":_vm._s(_vm.lang[_vm.getLanguage].PL_DESCRIPTION_TITLE)}}),_c('v-card-text',{staticClass:"px-0"},[_c('p',{class:_vm.windowWidth <= 768 ? 'charged-body-h2' : 'charged-body-h1',domProps:{"innerHTML":_vm._s(_vm.lang[_vm.getLanguage].PL_DESCRIPTION_TEXT)}})])],1)],1),_c('section',{staticClass:"d-flex align-end justify-center mx-0 px-0",attrs:{"id":"launch-factory"}},[_c('v-img',{staticClass:"px-0 mx-0",attrs:{"width":"100%","min-height":"450px","gradient":"","src":_vm.windowWidth <= 768
          ? require('../../../public/img/launch/FactoryMobile.png')
          : require('../../../public/img/launch/Factory.png')}})],1),_c('section',{staticClass:"pt-md-15 pt-0 pb-16",staticStyle:{"background":"#262626"},attrs:{"id":"launch-believe"}},[_c('v-row',{staticClass:"mx-auto mx-md-16 px-5 px-md-0 pt-15 pb-16 text-over-image-white"},[_c('v-col',{staticClass:"px-md-3 px-0",attrs:{"cols":"12","md":"6"}},[_c('v-card-title',{class:'px-md-4 px-0 ' +
            (_vm.windowWidth <= 768
              ? 'charged-title-mobile'
              : 'charged-factory-title '),staticStyle:{"word-break":"break-word"},domProps:{"textContent":_vm._s(_vm.lang[_vm.getLanguage].PL_BELIEVE_TITLE)}})],1),_c('v-col',{staticClass:"pb-7 px-md-3 px-0",attrs:{"cols":"12","md":"6"}},[_c('v-card-text',{staticClass:"charged-factory-text px-md-4 px-0",domProps:{"innerHTML":_vm._s(_vm.lang[_vm.getLanguage].PL_BELIEVE_CONTENT)}})],1)],1)],1),_c('section',{staticClass:"d-flex align-end justify-center mx-0 px-0",attrs:{"id":"launch-rimba"}},[_c('v-img',{staticClass:"px-0 mx-0",attrs:{"width":"100%","min-height":"450px","gradient":"","src":_vm.windowWidth <= 768
          ? require('../../../public/img/launch/RimbaBikeMobile.png')
          : require('../../../public/img/launch/RimbaBike.png')}})],1),_c('section',{attrs:{"id":"launch-rimba-project"}},[_c('v-row',{staticClass:"mx-auto mx-md-16 px-5 px-md-0 mt-13 mb-16 pb-7"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',{class:'px-md-4 px-0 pb-md-2 ' +
            (_vm.windowWidth <= 768
              ? 'charged-title-mobile'
              : 'charged-rimba-title'),domProps:{"textContent":_vm._s(_vm.lang[_vm.getLanguage].PL_RIMBA_TITLE)}})],1),_c('v-col',{staticClass:"px-md-3 px-0",attrs:{"cols":"12","md":"7"}},[_c('v-card-text',{staticClass:"charged-rimba-text px-md-4 px-0 pb-0",staticStyle:{"padding-top":"6px"},domProps:{"textContent":_vm._s(_vm.lang[_vm.getLanguage].PL_RIMBA_CONTENT)}})],1),_c('v-col',{class:'d-flex px-md-3 px-0 ' +
          (_vm.windowWidth <= 560 ? 'justify-start' : 'justify-end'),attrs:{"cols":"12","md":"5"}},[_c('v-btn',{staticClass:"charged-rimba-button pa-0 no-background-hover",attrs:{"text":"","ripple":false},on:{"click":function($event){return _vm.routerGo('/rimba')}}},[_c('span',{staticClass:"pr-2 charged-rimba-button text-primary",domProps:{"textContent":_vm._s(_vm.lang[_vm.getLanguage].PL_RIMBA_BUTTON)}}),_c('v-img',{attrs:{"src":require("../../../public/img/launch/RimbaLogo.png"),"max-width":_vm.windowWidth <= 560 ? '120px' : '150px'}}),_c('v-icon',{staticClass:"text-primary",attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1)],1)],1)],1),_c('section',{staticClass:"px-md-7",staticStyle:{"background":"#6b4693"},attrs:{"id":"launch-contact"}},[_c('v-row',{staticClass:"mt-6 pt-6 pt-md-16 flex-column flex-md-row mx-0 px-5 mx-md-16 px-md-0",attrs:{"justify":"space-around","align":"center"}},[_c('v-col',{staticClass:"px-0 pb-10 mt-md-9",attrs:{"cols":"12","md":"10"}},[_c('v-card-title',{class:'px-0 text-over-image-white py-0 ' +
            (_vm.windowWidth <= 768 ? 'charged-title-mobile' : 'charged-title'),staticStyle:{"word-break":"break-word"},domProps:{"innerHTML":_vm._s(_vm.lang[_vm.getLanguage].PL_CONTACT_TITLE)}})],1),_c('v-col',{staticClass:"px-0 py-auto d-flex",staticStyle:{"align-self":"center"},attrs:{"cols":"12","md":"2"}},[_c('v-btn',{class:'charged-button-text no-uppercase py-md-3 px-md-8 ' +
            (_vm.windowWidth <= 768 ? 'charged-button' : 'charged-button-mobile'),staticStyle:{"color":"#6b4693"},attrs:{"width":_vm.windowWidth <= 768 ? '100%' : 'fit-content',"color":"white","x-large":"","elevation":"2"},on:{"click":function($event){return _vm.startChat()}}},[_vm._v(" "+_vm._s(_vm.lang[_vm.getLanguage].PL_CONTACT_BUTTON)+" "),_c('v-icon',{attrs:{"right":"","color":"primary"}},[_vm._v(" mdi-whatsapp ")])],1)],1),_c('v-col',{staticClass:"px-0 pt-md-0 mb-16 pb-9",attrs:{"cols":"12"}},[_c('p',{staticClass:"charged-body-h2 text-over-image-white",domProps:{"innerHTML":_vm._s(
            _vm.windowWidth < 768
              ? _vm.lang[_vm.getLanguage].PL_CONTACT_TEXT_MOBILE
              : _vm.lang[_vm.getLanguage].PL_CONTACT_TEXT
          )}})])],1)],1),_c('v-footer',{staticClass:"pa-0"},[_c('Footer',{attrs:{"id":"footer","isMobileDevice":_vm.isMobileDevice,"dark":_vm.dark,"drawer":_vm.drawer,"windowWidth":_vm.windowWidth}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }